import React, {useEffect, useState} from 'react'
import {
  StyledFilterModalFooterButton,
  StyledFilterModalHeader,
  StyledFilterModalContainer,
  StyledFilterModalFooter,
  StyledOption,
} from './styles'

import PortalModal from '../../../ComponentsV2/common/Modal/PortalModal'

import CheckBoxInput from '../../../ComponentsV2/common/CheckBoxInput'
import RadioInput from '../../../ComponentsV2/common/RadioInput'

const FilterSelectorMobile = ({
  isModalOpen,
  onClose,
  filterSelected,
  applyFilter,
  appliedFilters,
  clearFilterType,
}) => {
  const {options, type: selectionType, key, label} = filterSelected

  const [selectedOptions, setSelectedOptions] = useState(
    appliedFilters[key] ?? []
  )

  const handleCheckboxChange = (event) => {
    const item = event.target.value
    const checked = event.target.checked
    if (checked) {
      setSelectedOptions([...selectedOptions, item])
    } else {
      setSelectedOptions(
        selectedOptions.filter((selectedItem) => selectedItem !== item)
      )
    }
  }

  const handleRadioChange = (e) => {
    setSelectedOptions([e.target.value])
  }

  const handleModalClose = () => {
    setSelectedOptions(appliedFilters[key] ?? [])
    onClose()
  }

  const handleApplyFilter = () => {
    applyFilter(key, selectedOptions)
    handleModalClose()
  }

  useEffect(() => {
    if (appliedFilters[key]?.length) {
      setSelectedOptions(appliedFilters[key])
    } else setSelectedOptions([])
  }, [appliedFilters, key])

  return (
    <StyledFilterModalContainer>
      <PortalModal
        showModal={isModalOpen}
        onClose={handleModalClose}
        additionalContentClass="filter-modal"
        isPadding={true}
      >
        <StyledFilterModalHeader>{label}</StyledFilterModalHeader>

        {options.map(({value, label}) => (
          <StyledOption>
            {selectionType === 'multiple' ? (
              <CheckBoxInput
                key={value}
                id={value}
                labelProps={{className: 'option-label'}}
                name="filter-options"
                labelPosition="end"
                justifyContent="space-between"
                onChange={handleCheckboxChange}
                value={value}
                label={label}
                checked={selectedOptions.includes(value)}
              />
            ) : (
              <RadioInput
                key={value}
                id={value}
                name="filter-options"
                labelPosition="end"
                justifyContent="space-between"
                onChange={handleRadioChange}
                value={value}
                label={label}
                checked={selectedOptions.includes(value)}
              />
            )}
          </StyledOption>
        ))}

        <StyledFilterModalFooter>
          <StyledFilterModalFooterButton
            onClick={() => clearFilterType(filterSelected.key)}
          >
            Clear
          </StyledFilterModalFooterButton>
          <StyledFilterModalFooterButton
            variant="primary"
            onClick={handleApplyFilter}
          >
            Apply
          </StyledFilterModalFooterButton>
        </StyledFilterModalFooter>
      </PortalModal>
    </StyledFilterModalContainer>
  )
}

export default FilterSelectorMobile
