import React, {useEffect, useState} from 'react'
import noop from 'lodash/noop'

import {StyledFilterChipsContainer} from './styles'
import Responsive from '../../../ComponentsV2/HOC/Responsive'
import FilterChip from './FilterChip'
import FilterSelectorMobile from './FilterSelectorMobile'
import FilterSelectorDesktop from './FilterSelectorDesktop'
import useFilters from './useFilters'

/**
 * @param {{
 * onFilterApply: (key: string, newState:Record<string, any>) => void,
 * filters: Array,
 * isMobile: boolean
 * }} param0
 * @returns
 */
const FiltersV2 = ({isMobile = false, filters = [], onFilterApply = noop}) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)
  const [
    {appliedFilters},
    {clearFilterType, clearFilters, applyFilter, removeFilter},
  ] = useFilters({onFilterApply})

  const [currentSelectedFilterType, setCurrentSelectedFilterType] =
    useState(null)

  const handleFilterTypeClick = (filter) => {
    if (!currentSelectedFilterType) {
      setCurrentSelectedFilterType(filter)
    } else setCurrentSelectedFilterType(null)
    setIsSelectorOpen(!isSelectorOpen)
  }

  const handleModalClose = () => {
    setCurrentSelectedFilterType(null)
    setIsSelectorOpen(false)
  }

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('click', handleModalClose)
      return () => window.removeEventListener('click', handleModalClose)
    }
  }, [isMobile])

  useEffect(() => {
    if (filters.length) {
      clearFilters()
    }
    return () => {
      if (filters.length) clearFilters()
    }
  }, [filters])

  return (
    <>
      <StyledFilterChipsContainer
        onClick={(e) => e.stopPropagation()}
        isMobile={isMobile}
        className="category-filters"
      >
        {filters.map((filter) => {
          return (
            <div className="filter">
              <FilterChip
                isSelected={currentSelectedFilterType?.key === filter.key}
                isFilterApplied={!!appliedFilters[filter.key]}
                appliedFilters={appliedFilters}
                filter={filter}
                onClick={() => handleFilterTypeClick(filter)}
              />

              {!isMobile &&
                currentSelectedFilterType?.key === filter.key &&
                isSelectorOpen && (
                  <FilterSelectorDesktop
                    filterSelected={currentSelectedFilterType}
                    onClose={handleModalClose}
                    appliedFilters={appliedFilters}
                    clearFilterType={clearFilterType}
                    applyFilter={applyFilter}
                  />
                )}
            </div>
          )
        })}

        {Object.keys(appliedFilters).length > 0 ? (
          <div className="clearall" onClick={clearFilters}>
            Clear All
          </div>
        ) : null}
      </StyledFilterChipsContainer>

      {isMobile && currentSelectedFilterType && (
        <FilterSelectorMobile
          isModalOpen={isSelectorOpen}
          filterSelected={currentSelectedFilterType}
          onClose={handleModalClose}
          applyFilter={applyFilter}
          removeFilter={removeFilter}
          appliedFilters={appliedFilters}
          clearFilterType={clearFilterType}
        />
      )}
    </>
  )
}

export default Responsive(FiltersV2)
