import React, {useMemo, useState} from 'react'

import {ArrowHead} from '../../../ComponentsV2/common/Arrows/ArrowHead'
import {StyledFilterChip} from './styles'

const FilterChip = ({
  isSelected,
  isFilterApplied,
  appliedFilters,
  filter,
  ...rest
}) => {
  const [optionsMap] = useState(() => {
    const map = {}
    filter.options.forEach((option) => {
      map[option.value] = option
    })
    return map
  })
  const chipLabel = useMemo(() => {
    if (appliedFilters[filter.key] && filter.type === 'single') {
      return optionsMap[appliedFilters[filter.key]]?.label
    }

    if (appliedFilters[filter.key] && filter.type !== 'single') {
      return `${filter.label} (${appliedFilters[filter.key].length})`
    }
    return filter.label
  }, [appliedFilters, filter.key, filter.label, filter.type, optionsMap])

  return (
    <StyledFilterChip isSelected={isFilterApplied} {...rest}>
      <div className="label"> {chipLabel}</div>
      <ArrowHead
        direction={isSelected ? 'up' : 'down'}
        isSelected={isFilterApplied}
        color="var(--category-primary-color)"
      />
    </StyledFilterChip>
  )
}

export default FilterChip
