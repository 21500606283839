import noop from 'lodash/noop'
import React, {useCallback, useEffect, useRef} from 'react'

import LightCloseIcon from '../../../assets/icons/close1.svg'
import CloseIcon from '../../../assets/icons/close.svg'
import Portal from './Portal'
import {
  CloseButton,
  StyledContent as ModalContent,
  StyledTitle as ModalTitle,
  StyledModal,
} from './styles'

const PortalModal = ({
  onClose = noop,
  children,
  showModal,
  title = '',
  isPadding = false,
  additionalContentClass = '',
  additionalModalClass = '',
  addtionalButtonClass = '',
  hideCloseButton = false,
  cancelCloseIcon = false,
}) => {
  const modalRef = useRef()
  const handleClick = useCallback(
    (clickEvent) => {
      if (clickEvent.target === modalRef.current) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (showModal) {
      window.document.body.style.overflow = 'hidden'
    } else {
      window.document.body.style.overflow = 'auto'
    }
  }, [showModal])

  useEffect(() => {
    return () => {
      window.document.body.style.overflow = 'auto'
    }
  }, [])

  if (!showModal) {
    return null
  }

  return (
    <Portal>
      <StyledModal
        ref={modalRef}
        onClick={handleClick}
        className={additionalModalClass}
      >
        <ModalContent
          className={additionalContentClass}
          data-identifier="modal-content"
          isPadding={isPadding}
        >
          {!hideCloseButton && (
            <CloseButton onClick={onClose} className={addtionalButtonClass}>
              <img
                src={cancelCloseIcon ? LightCloseIcon : CloseIcon}
                loading="lazy"
                alt="close"
              />
            </CloseButton>
          )}
          {title && <ModalTitle>{title}</ModalTitle>}
          {children}
        </ModalContent>
      </StyledModal>
    </Portal>
  )
}

export default PortalModal
