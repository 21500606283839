import noop from 'lodash/noop'
import {useCallback, useState} from 'react'

/**
 * @param {{onFilterApply?: (key:string, appliedFilter: string[])=> void}}
 * Common Hook which maintains filter state
 * TODO: persist filter state on refresh
 */
const useFilters = ({onFilterApply = noop}) => {
  // intermediate filter state, source of truth will be url search params
  const [appliedFilterState, setAppliedFilterState] = useState({})

  const applyFilter = useCallback(
    //key: string, value: string[]
    (key, value) => {
      const newState = {
        ...appliedFilterState,
        [key]: value,
      }

      if (newState[key].length === 0) {
        delete newState[key]
      }
      setAppliedFilterState(newState)
      onFilterApply(key, newState)
    },
    [appliedFilterState, onFilterApply]
  )

  // takes key string
  const clearFilterType = useCallback(
    (key) => {
      const newState = {...appliedFilterState}
      delete newState[key]
      setAppliedFilterState(newState)
      onFilterApply(key, newState)
    },
    [appliedFilterState, onFilterApply]
  )

  const clearFilters = () => {
    setAppliedFilterState({})
    onFilterApply('', {})
  }

  const actions = {
    applyFilter,
    clearFilterType,
    clearFilters,
  }

  const state = {
    appliedFilters: appliedFilterState,
  }

  return [state, actions]
}

export default useFilters
