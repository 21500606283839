import styled from 'styled-components'

export const StyledRadioContainer = styled.label`
  /* The container */
  display: flex;
  flex-direction: ${({labelPosition = 'start'}) =>
    labelPosition === 'start' ? 'flex-start' : 'row-reverse'};
  justify-content: ${({justifyContent = 'start'}) => justifyContent};
  gap: 6px;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .radio {
    position: relative;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 2px solid var(--category-primary-dark-color);
    background: white;
  }

  .radio.checkmark {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: var(--category-primary-dark-color);
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .radio {
    background-color: #f1f1f1;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .radio .checkmark {
    background-color: var(--category-primary-dark-color);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .radio .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ .radio .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .radio .checkmark:after {
    width: 12px;
    height: 12px;
    top: 4px;
    left: 4px;
    border-radius: 50%;
    background: var(--category-primary-dark-color);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
