import styled from 'styled-components'

export const StyledFilterChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: 7px 16px;
  gap: 8px;
  background: ${({isSelected}) =>
    isSelected
      ? 'var(--category-primary-color)'
      : 'var(--category-primary-bg2-color)'};
  color: ${({isSelected}) =>
    isSelected
      ? 'var(--brand-primary-white-color)'
      : 'var(--category-primary-color)'};
  border-radius: 16px;
  cursor: pointer;

  .label {
    font-size: 16px;
    font-weight: 500;
  }

  @media (max-width: 480px) {
    height: 32px;
  }
`

export const StyledFilterChipsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 1124px;
  gap: 8px;
  padding: 4px 0px;
  overflow-y: ${({isMobile}) => (isMobile ? 'auto' : 'visible')};
  .filter {
    position: relative;
  }
  .clearall {
    white-space: nowrap;
    cursor: pointer;
    margin-left: 10px;
  }
  .label {
    white-space: nowrap;
    overflow: hidden;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const StyledOption = styled.div`
  padding: 14px 16px;
  font-size: 14px;
  > * .option-label {
    font-size: 14px;
  }
`

export const StyledFilterModalFooterButton = styled.div`
  border-top: 1px solid #e3e3e3;
  border-left: ${({variant}) =>
    variant === 'primary' ? '1px solid #e3e3e3' : 'none'};
  margin-top: 10px;
  padding: 16px 14px;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({variant}) =>
    variant === 'primary'
      ? 'var(--category-primary-color)'
      : 'var(--brand-primary-error-color)'};
  font-weight: 900;
`

export const StyledFilterModalHeader = styled.div`
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 10px;
  padding: 16px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 900;
`

export const StyledFiltersDropdownList = styled.div`
  min-width: 160px;
  position: absolute;
  list-style: none;
  padding: 0;
  top: 48px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
  background-color: var(--brand-primary-white-color);
  border-radius: 12px;
  overflow-y: visible;
  height: max-content;
  z-index: 1;
  .options-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
  }
`

export const StyledFilterModalContainer = styled.div`
  > * .filter-modal {
    padding: 0px;
  }
  > * .options-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
  }
`

export const StyledFilterModalFooter = styled.div`
  display: flex;
`
