import {StyledRadioContainer} from './styles'

const RadioInput = ({
  label,
  id,
  labelPosition,
  justifyContent,
  labelProps = {},
  ...rest
}) => {
  return (
    <StyledRadioContainer
      htmlFor={id}
      labelPosition={labelPosition}
      justifyContent={justifyContent}
      {...labelProps}
    >
      <input type="radio" id={id} {...rest} />
      <div className="radio">
        <span className="checkmark"></span>
      </div>
      {label}
    </StyledRadioContainer>
  )
}

export default RadioInput
