import {StyledCheckboxContainer} from './styles'

const CheckBoxInput = ({
  label,
  id,
  labelPosition,
  justifyContent,
  labelProps = {},
  ...rest
}) => {
  return (
    <StyledCheckboxContainer
      htmlFor={id}
      labelPosition={labelPosition}
      justifyContent={justifyContent}
      {...labelProps}
    >
      <input type="checkbox" id={id} {...rest} />
      <div className="checkbox">
        <span class="checkmark"></span>
      </div>
      {label}
    </StyledCheckboxContainer>
  )
}

export default CheckBoxInput
