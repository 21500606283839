import React, {useRef, useState, useEffect} from 'react'
import {
  StyledFilterModalFooter,
  StyledFiltersDropdownList,
  StyledOption,
  StyledFilterModalFooterButton,
} from './styles'
import CheckBoxInput from '../../../ComponentsV2/common/CheckBoxInput'
import RadioInput from '../../../ComponentsV2/common/RadioInput'

/**
 * Filter Selector for Desktop only
 */
const FilterSelectorDesktop = ({
  filterSelected,
  appliedFilters,
  clearFilterType,
  applyFilter,
  onClose,
}) => {
  const {options, type: selectionType, key} = filterSelected
  const listRef = useRef(null)

  const [selectedOptions, setSelectedOptions] = useState(
    appliedFilters[key] ?? []
  )

  const handleCheckboxChange = (event) => {
    const item = event.target.value
    const checked = event.target.checked
    if (checked) {
      setSelectedOptions([...selectedOptions, item])
    } else {
      setSelectedOptions(
        selectedOptions.filter((selectedItem) => selectedItem !== item)
      )
    }
  }

  const handleRadioChange = (e) => {
    setSelectedOptions([e.target.value])
  }

  const handleModalClose = () => {
    setSelectedOptions(appliedFilters[key] ?? [])
    onClose()
  }

  const handleApplyFilter = () => {
    applyFilter(key, selectedOptions)
    handleModalClose()
  }

  useEffect(() => {
    if (appliedFilters[key]?.length) {
      setSelectedOptions(appliedFilters[key])
    } else setSelectedOptions([])
  }, [appliedFilters, key])

  return (
    <StyledFiltersDropdownList ref={listRef}>
      {options.map(({value, label}) => (
        <StyledOption>
          {selectionType === 'multiple' ? (
            <CheckBoxInput
              key={value}
              id={value}
              name="filter-options"
              labelPosition="end"
              justifyContent="space-between"
              onChange={handleCheckboxChange}
              value={value}
              label={label}
              checked={selectedOptions.includes(value)}
            />
          ) : (
            <RadioInput
              key={value}
              id={value}
              name="filter-options"
              labelPosition="end"
              justifyContent="space-between"
              onChange={handleRadioChange}
              value={value}
              label={label}
              checked={selectedOptions.includes(value)}
            />
          )}
        </StyledOption>
      ))}

      <StyledFilterModalFooter>
        <StyledFilterModalFooterButton
          onClick={() => clearFilterType(filterSelected.key)}
        >
          Clear
        </StyledFilterModalFooterButton>
        <StyledFilterModalFooterButton
          variant="primary"
          onClick={handleApplyFilter}
        >
          Apply
        </StyledFilterModalFooterButton>
      </StyledFilterModalFooter>
    </StyledFiltersDropdownList>
  )
}

export default FilterSelectorDesktop
